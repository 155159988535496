<!--
 * @Description: 尾部
 * @Author: lynn
 * @Date: 2023-05-18 15:44:32
 * @LastEditTime: 2023-05-30 16:46:53
 * @LastEditors: lynn
-->
<template>
  <div id="contact" class="footer">
    <base-title theme="white" title="联系我们"  />
    <a-row class="footer-row">
      <template v-for="item in text">
        <a-col class="footer-col" :key="item.id" :xs="{ span: 24}" :lg="{ span: item.span}">
          <p v-if="item.href">备案号：<a :href="item.href">{{item.text}}</a></p>
          <p v-else>{{item.text}}</p>  
        </a-col>
      </template> 
    </a-row>
  </div>
</template>

<script>
import BaseTitle from './base-title/base-title.vue'
const text = [
  {text: '地址：四川省成都市高新区天府大道北段1700号环球中心W5-2105', span: 12},
  {text: '电话：400-157-9098', span: 5},
  // {text: '传真：（+86）010-50986901', span: 4},
  {text: '蜀ICP备2023013063号-1', span: 5, href: 'https://beian.miit.gov.cn/'},
  ]
export default {
  components: { BaseTitle },
  data() {
    return {
      text
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
  padding: 40px 46px;
  background: #013f8a;
  color: #fff;
  &-row{
    padding: 10px 0;
  }
  &-col{
    padding: 10px 0;
    font-size: 14px;
    // text-align: left;
    p{
      margin-bottom: 0;
    }
  }
}
@media only screen and (max-width: 992px) {
  .footer{
    padding: 20px 16px;
    &-col{
      text-align: left;
    }
  } 
}
</style>