<!--
 * @Description: 
 * @Author: lynn
 * @Date: 2022-01-07 17:15:25
 * @LastEditTime: 2023-07-05 15:24:30
 * @LastEditors: lynn
-->
<template>
  <div class="top-menu">
    <div class="container">
        <div class="logo"> <img :src="logo" alt=""></div>
        <ul>
          <li v-for="item in menus" :href="`#${item.key}`" @click="handleMenuClick(item)" :class="{'active': current === item.key}" :key="item.key">{{item.text}}</li>
        </ul>
    </div>
    
  </div>
</template>

<script>
const menus = [
  {text: '首页', key: 'home'},
  {text: '关于我们', key: 'about'},
  // {text: '产品体系', key: 'product'},
  // {text: '团队介绍', key: 'team'},
  {text: '联系我们', key: 'contact'},
]
export default {
  data(){
    return {
      logo:require('../../../assets/images/logo@2x.png'),
      menus,
      current: 'home'
    }
  },
  methods:{
    handleMenuClick(item){
      const { key } = item
      this.current = key
     
      $('html, body').animate({
        scrollTop: $("#"+key).offset().top - 115
      }, 600)
      
    },
    handleClick(){
      this.show = !this.show
      this.$emit('menuClick')
    },    
  },
  created() {
    
  },
  computed:{
      
  },
  watch:{
    
  }
}
</script>

<style lang="less" scoped>
.top-menu{
    // width: calc(100% - 520px);
    // padding: 0 260px;
    position: fixed;
    width: 100vw;
    padding: 0 30px;
    background-color: rgba(1, 63, 138, 1);
    z-index: 11;
    .container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      .logo{
        width: 75px;
      }
      img{
        width: 100%;
      }
      ul{
        list-style: none;
        display: flex;
        margin-bottom: 0;
        li{
          width: 112px;
          padding: 40px 0;
          margin-right: 60px;
          font-weight: 400;
          font-size: 20px;
          text-align: left;
          color: #fff;
          text-align: center;
          transition: all .15s linear;
          cursor: pointer;
          &.active{
            color: #36A8FF;
            border-bottom: 3px solid #36A8FF;
          }
          &:hover{
            color: #36A8FF;
          }
        }
      }
    }
}
@media only screen and (max-width: 992px) {
  .top-menu{
    padding: 0 15px;
    .container{
      .logo{
        width: 30px;
      }
      ul{
        // display: none;
        li{
          width: auto;
          font-size: 12px;
          margin-right: 10px;
          padding: 20px 0;
        }
      }
    }
  }
}

</style>