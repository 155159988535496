/*
 * @Description: 
 * @Author: lynn
 * @Date: 2022-01-07 17:08:34
 * @LastEditTime: 2023-05-19 15:48:26
 * @LastEditors: lynn
 */
import BaseLayout from '@/layout/default/index.vue'
export const asyncRouterMap = [
    {
        path:'/',
        name:'Index',
        component:BaseLayout,
        redirect:'/home',
        children:[
            {
                path:'/home',
                name:'home',
                component:()=> import('@/views/home/index.vue'),
                meta:{
                    title:'首页'
                }
            },
            
            
        ]
    }
]