<!--
 * @Description: 页面基础布局框架
 * @Author: lynn
 * @Date: 2022-01-07 17:16:21
 * @LastEditTime: 2023-05-19 12:50:15
 * @LastEditors: lynn
-->
<template>
  <div class="page-layout">
    <base-menu @menuClick="handleMenuClick"/>
    <!-- <navagation ref="nav"/> -->
    <div class="page-layout-main">
         <router-view/>
    </div>
    <my-footer/>
  </div>
</template>

<script>
import BaseMenu from './menu/nav-bar.vue'
// import Navagation from './navagation/index.vue'
import MyFooter from '../../components/footer.vue'
export default {
components:{
    BaseMenu,
    // Navagation,
    MyFooter
},
methods:{
  handleMenuClick(){
    this.$refs.nav.show = !this.$refs.nav.show 
  }
}
}
</script>

<style>

</style>