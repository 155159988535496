<!--
 * @Description: App
 * @Author: lynn
 * @Date: 2022-01-06 14:04:46
 * @LastEditTime: 2022-01-24 13:42:43
 * @LastEditors: lynn
-->
<template>
  <div id="app">
    <transition name="slid-right">
      <keep-alive>
         <router-view/>
      </keep-alive>   
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{
  padding: 0;
  margin: 0;
}
.page{
    width: 100vw;
    min-height: 100vh;
    background-size: 100% 100%;
    background-position: center;
}
  /*右滑动效*/
.slid-right-enter{
  opacity: 1;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0)
}
.slid-right-leave-active{
  opacity: 1;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0)
}
</style>
