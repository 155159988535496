<!--
 * @Description: 基础title组件
 * @Author: lynn
 * @Date: 2023-05-18 15:47:17
 * @LastEditTime: 2023-05-19 11:01:08
 * @LastEditors: lynn
-->
<template>
  <div class="title">
    <h3 :class="theme"> {{title}} </h3>
    <img :src="srcBg" />
  </div>
</template>

<script>
import title1 from '../../assets/images/title1@2x.png'
import title2 from '../../assets/images/title2@2x.png'
export default {
  computed: {
    srcBg() {
      return this.theme === 'blue' ? title1 : title2
    }
  },
  props:{
    title: {
      type: String,
      default: '标题'
    },
    theme: {
      type: String,
      default: 'blue'
    }
  }
}
</script>

<style lang="less" scoped>
.title{
  text-align: left;
}
h3{
  color: #023382;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 6px;
  margin-bottom: 0;
}
img{
  width: 116px;
}
.white{
  color: #fff;
}
</style>