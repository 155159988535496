/*
 * @Description: 
 * @Author: lynn
 * @Date: 2022-01-06 14:04:46
 * @LastEditTime: 2023-05-18 16:51:36
 * @LastEditors: lynn
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
Vue.use(Antd);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
